var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-center": "",
        "justify-center": "",
        row: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "text-sm-center",
          attrs: { xs10: "", md8: "", lg6: "", xl4: "" }
        },
        [
          _c("header", [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("@/assets/images/IMG-logo.svg") }
            })
          ]),
          _c(
            "div",
            { staticClass: "elementPanel elementPanel--shadowed" },
            [
              !_vm.isSubmitted
                ? [
                    _c(
                      "v-form",
                      {
                        ref: "resetPasswordForm",
                        attrs: { "lazy-validation": "" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submit($event)
                          }
                        }
                      },
                      [
                        _c("h2", [_vm._v(_vm._s(_vm.$t("auth.reset.title")))]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("auth.reset.instruction")))
                        ]),
                        _c(
                          "v-layout",
                          { staticClass: "v-form__inner" },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("MiPassword", {
                                  ref: "miPassword",
                                  attrs: {
                                    visibility: _vm.visibility,
                                    icons: _vm.icons
                                  },
                                  model: {
                                    value: _vm.isPasswordValid,
                                    callback: function($$v) {
                                      _vm.isPasswordValid = $$v
                                    },
                                    expression: "isPasswordValid"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          [
                            _c(
                              "MiButton",
                              {
                                staticClass: "float-left",
                                attrs: { disabled: !_vm.isPasswordValid },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.submit($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("auth.reset.submit")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "MiButton",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  color: "primary-light",
                                  outline: true
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.logout($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("auth.logout")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.isSubmitted
                ? [
                    _c(
                      "v-layout",
                      { staticClass: "text-xs-center", attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c("h2", [
                            _vm._v(_vm._s(_vm.$t("auth.submitted.success")))
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("auth.submitted.successIntruction"))
                            )
                          ])
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "v-icon--completed" },
                                  [_vm._v("check")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              [
                                !_vm.redirect
                                  ? _c(
                                      "MiButton",
                                      { attrs: { to: "/login" } },
                                      [_vm._v(_vm._s(_vm.$t("auth.login")))]
                                    )
                                  : _vm._e(),
                                _vm.redirect
                                  ? _c(
                                      "MiButton",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.redirectRoute($event)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("auth.login")))]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }