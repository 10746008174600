import { Component, Vue } from 'vue-property-decorator';
import { identifyApplication, identifyHostname } from '@/support/Client';
import axios, { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import MiPassword, { MiPasswordVisibility, MiPasswordIcons } from '@/components/mi-password/MiPassword';

@Component<ResetPassword>({})
export default class ResetPassword extends Vue {
  protected oAuthServer: string = identifyHostname() || '';

  protected oAuthClientId = 1;

  protected oAuthSecret = 'secret';

  protected oAuthScope = '*';

  protected isPasswordValid = false;

  protected isSubmitted = false;

  protected redirect = false;

  protected visibility: MiPasswordVisibility = {
    ruleList: true,
    showPassword: true,
  }

  protected icons: MiPasswordIcons = {
    password: 'lock_open',
    confirmation: 'lock_open',
  };

  protected canSubmit(password: string, confirmation: string) {
    if (! password || ! confirmation || ! this.getEmail() || ! this.getToken()) {
      return false;
    }
    return password === confirmation;
  }

  protected submit() {
    const miPassword: MiPassword = this.$refs.miPassword as MiPassword;

    if (! miPassword) {
      return;
    }

    const password = miPassword.getPassword().password;
    const confirmation = miPassword.getPassword().confirmation;

    if (this.$store.state.Auth.force_password_change) {
      if (! password || ! confirmation) {
        return;
      }
      this.resetPasswordByAdmin();
      return;
    }

    if (! this.canSubmit(password, confirmation)) {
      return;
    }

    const payload = {
      email: this.getEmail(),
      password,
      password_confirmation: confirmation,
      token: this.getToken(),
    };

    const headers = {};

    if (window.location.hostname !== 'localhost') {
      (headers as any)['X-Application'] = identifyApplication();
    }

    const client = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });

    client
      .post('/v1/password/reset', payload)
      .then((response: AxiosResponse) => {
        this.isSubmitted = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.alert('The password and password confirmation should be the same.', 'Missmatching passwords');
      });
  }

  protected resetPasswordByAdmin() {
    const miPassword: MiPassword = this.$refs.miPassword as MiPassword;
    const password = miPassword.getPassword().password;

    const user = this.$store.state.Auth;

    const payload = {
      password,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      uuid: user.uuid,
      force_password_change: false,
    };

    user
      .update(payload)
      .then((response: AxiosResponse) => {
        this.isSubmitted = true;
        this.redirect = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.alert('The password and password confirmation should be the same.', 'Missmatching passwords');
      });
  }

  protected redirectRoute() {
    this.$store.dispatch('userDeauthenticated');
    this.$router.push('/login');
  }

  protected logout() {
    this.$store.dispatch('userDeauthenticated');
    this.$router.push('/login');
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  public getToken() {
    return this.$route.query.token;
  }

  public getEmail() {
    return this.$route.query.email;
  }
}
